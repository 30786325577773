import React from "react";
import ComingSoon from "../Components/ComingSoon";

const Settings = () => {
    return (
        <ComingSoon/>
    );
}

export default Settings;
